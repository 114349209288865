<template>
    <div>
      <el-table
			:data="tableData"
			style="width: 100%">
				<el-table-column
					prop="date"
					:label="item.label"
					width="180"
					v-for="(item, index) in head"
					:key="index">
					<template slot-scope="scope">
						{{ scope.row[item.key] }}
					</template>
				</el-table-column>
			</el-table>  
    </div>
</template>

<script>
export default {
    name: 'StoreWebCommission',

    components: {  },

    directives: {  },

    data() {
        return {
					head: [
						{ label:'姓名', key: 'name'}, 
						{ label:'性别', key: 'phone'},
					],
					tableData: [
						{ id: 1, name: 909, phone: 'abc', kkk:{} },
						{ id: 2, name: 3003, phone: 'efg' },
						{ id: 3, name: 233, phone: 'xyz' }
					],
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="less" scoped>

</style>